@import '../../../../styles/variables';

.layout {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  margin: 0 8px;
  padding-bottom: 40px;
}

.title {
  font-size: 24px;
  padding: 16px;
  text-align: center;
}

.description {
  max-width: 600px;
}

.options {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  gap: 24px;
}

.option {
  transition: background-color 0.3s ease-out;
  padding: 24px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  border-radius: 12px;

  &:hover {
    background-color: #F1F2F2;
  }

  img {
    width: 200px;
    height: 200px;
    object-fit: contain;
  }
}
